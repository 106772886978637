import React, { useState, useRef, useEffect, useCallback } from 'react';

import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import './MarketingPerformance.css'
import "ag-grid-enterprise";
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';

import { currencyFormatter } from '../valueformatter';

export default function MarketingPerformance(props) {
    const gridOptions = {
        sideBar: true,
        rowGroupPanelShow: 'always',
        groupIncludeTotalFooter: true,
        suppressAggFuncInHeader: true,
        columnHoverHighlight: true,
        autoGroupColumnDef: {
            cellRendererParams: {
                footerValueGetter: params => {
                    const isRootLevel = params.node.level === -1;
                    return isRootLevel ? 'Grand Total' : `Sub Total (${params.value})`;
                },
            },
            headerName: 'Campaign',
        },
        getRowStyle: params => {
            // Grand Total row formatting
            if (params.node.group && params.node.level === -1) {
                return { backgroundColor: '#3F91B8', color: 'white', fontWeight: 'bold' };
            }
            // Subtotal row formatting
            if (params.node.group) {
                return { backgroundColor: '#b5c4ca', fontWeight: 'bold' };
            }
            // Alternating row colors
            return { backgroundColor: params.node.rowIndex % 2 === 0 ? '#f9f9f9' : '#c3e6f7' };
        },
        autoSizeStrategy: {
            type: 'fitCellContents',
        },
    };

    const gridRef = useRef(); // Optional - for accessing Grid's API

    // DefaultColDef sets props common to all Columns
    const defaultColDef = {
        filter: true,
    };

    // Row Agg Func
    const getGroupRowAgg = useCallback((params) => {
        const result = {
            spend: 0,
            initial_payment: 0,
            gross_sale: 0,
            ip_roi: 0,
            gross_roi: 0,
            count_of_sales: 0,
            cpa: 0,
            initial_value: 0,
            initial_ppa: 0,
        };

        params.nodes.forEach((node) => {
            // console.log(node.data);
            result.spend += node.data.spend;
            result.initial_payment += node.data.initial_payment ? node.data.initial_payment : 0;
            result.gross_sale += node.data.gross_sale ? node.data.gross_sale : 0;
            result.count_of_sales += node.data.count_of_sales ? node.data.count_of_sales : 0;
        });

        result.ip_roi = result.initial_payment / result.spend;
        result.gross_roi = result.gross_sale / result.spend;
        result.cpa = result.spend / result.count_of_sales;
        result.initial_value = result.initial_payment / result.count_of_sales;
        result.initial_ppa = result.initial_value - result.cpa;
        // console.log("result: ", result);

        return result;
    });


    // Column Definitions: Defines the columns to be displayed.
    const colDefs = [
        { 
            field: "source_name", 
            headerName: "Campaign", 
            enableRowGroup: true, 
            rowGroup: false, 
            sort: "asc", 
            pinned: 'left', 
            cellRenderer: params => {
                if (params.node.rowIndex === params.api.getDisplayedRowCount() - 1) {
                    return 'Grand Total';
                }
                return params.value;
            }
        },
        {
            field: "spend", headerName: "Cost", cellStyle: { textAlign: 'right' }, aggFunc: "sum",
            valueFormatter: params => currencyFormatter(params.value, "$"),
        },
        {
            field: "initial_payment", headerName: "Initials", cellStyle: { textAlign: 'right' }, aggFunc: "sum",
            valueFormatter: params => currencyFormatter(params.value, "$"),
            cellStyle: params => {
                if (params.value < 0) {
                    return { color: 'red' }; // Negative values in red
                }
            },
        },
        {
            field: "gross_sale", headerName: "Gross", cellStyle: { textAlign: 'right' }, aggFunc: "sum",
            valueFormatter: params => currencyFormatter(params.value, "$"),
        },
        {
            field: "ip_roi", headerName: "ROI", cellStyle: { textAlign: 'right' },
            valueFormatter: params => {
                // Ensure value is a number and format to 2 decimal points
                const value = parseFloat(params.value);
                return isNaN(value) ? '' : value.toFixed(2);
            }
        },
        {
            field: "gross_roi", headerName: "GROI", cellStyle: { textAlign: 'right' },
            valueFormatter: params => {
                // Ensure value is a number and format to 2 decimal points
                const value = parseFloat(params.value);
                return isNaN(value) ? '' : value.toFixed(2);
            }
        },
        {
            field: "count_of_sales", headerName: "Sold", cellStyle: { textAlign: 'right' }, aggFunc: "sum",
            valueFormatter: params => {
                const value = parseFloat(params.value);
                return isNaN(value) ? 0 : value;
            }
        },
        {
            field: "cpa", headerName: "CPA", cellStyle: { textAlign: 'right' }, aggFunc: "sum",
            valueFormatter: params => currencyFormatter(params.value, "$"),
        },
        {
            field: "initial_value", headerName: "Initial Value", cellStyle: { textAlign: 'right' }, aggFunc: "sum",
            valueFormatter: params => currencyFormatter(params.value, "$"),
        },
        {
            field: "initial_ppa", headerName: "Initial PPA", cellStyle: { textAlign: 'right' }, aggFunc: "sum",
            valueFormatter: params => currencyFormatter(params.value, "$"),
            cellStyle: params => {
                if (params.value < 0) {
                    return { color: 'red' }; // Negative values in red
                }
            },
        },

    ];

    // Row Data: The data to be displayed.
    const [rowData, setRowData] = useState([]);

    // Preserving Filter Model
    const [filterModel, setFilterModel] = useState(null);  // Save current filters

    // Loading state to track when data is being fetched
    const [loading, setLoading] = useState(true);

    // Fetch data based on date range
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); // Set loading to true before fetching
            if (gridRef.current) {
                // Save current filter state
                const currentFilterModel = gridRef.current.api.getFilterModel();
                setFilterModel(currentFilterModel);
            }

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    replaceKeys: {
                        "{{startDate}}": props.startDate,
                        "{{endDate}}": props.endDate,
                    }
                })
            };
            const reqUrl = `${process.env.REACT_APP_QF_API}/${process.env.REACT_APP_OTG_ID}/data/JVbizzTtcfk70MIQ74dP`;

            try {
                const result = await fetch(reqUrl, requestOptions);
                const respData = await result.json();
                // Check for error 
                if (respData.status !== "success") {
                    alert("Error fetching data: " + respData.msg);
                    setRowData([]);
                    return;
                }
                setRowData(respData.data);
            } catch (error) {
                console.error("ERROR: " + error);
            } finally {
                setLoading(false); // Set loading to false after fetching
            }
        };

        fetchData();
    }, [props.reportName, props.startDate, props.endDate]);

    // After new data is set, reapply the filter model
    useEffect(() => {
        if (gridRef.current && filterModel) {
            gridRef.current.api.setFilterModel(filterModel);
        }
    }, [rowData, filterModel]);

    // This will autosize these columns since I think the reason they're not be resized with the gridoption above
    // is b/c we're calculating them and creating here instead of the data coming straght from the API
    const onGridReady = (params) => {
        params.api.sizeColumnsToFit(); // Resize columns to fit the grid's width
        const columnIds = ['source_name', 'spend', 'initial_payment', 'gross_sale', 'ip_roi', 'gross_roi', 'count_of_sales', 'cpa', 'initial_value', 'initial_ppa'];
        params.api.autoSizeColumns(columnIds); // Auto-size the specific columns
    };

    return (
        // wrapping container with theme & size
        <div
            className="ag-theme-quartz h-78 greyBg" // applying the grid theme
        >
            <div id="summaryBox">
                <p id="summaryText">
                    <strong>Marketing Performance</strong><br />
                    This view will show each marketing lines cost, related initial pay, gross pay, deal count, cpa, average ip, and average profit
                </p>
            </div>
            <div style={{ position: 'relative', height: '85%' }}> {/* New wrapper for AG Grid */}
                <AgGridReact
                    enableCharts={true}
                    enableRangeSelection={true}
                    onGridReady={onGridReady}
                    rowData={rowData}
                    columnDefs={colDefs}
                    getGroupRowAgg={getGroupRowAgg}
                    gridOptions={gridOptions}
                    defaultColDef={defaultColDef}
                    modules={[ServerSideRowModelModule, RowGroupingModule]}
                    defaultSideBar={false}
                    sideBar={{
                        toolPanels: [
                            {
                                id: 'columns',
                                labelDefault: 'Columns',
                                labelKey: 'columns',
                                iconKey: 'columns',
                                toolPanel: 'agColumnsToolPanel',
                            },
                            {
                                id: 'filters',
                                labelDefault: 'Filters',
                                labelKey: 'filters',
                                iconKey: 'filter',
                                toolPanel: 'agFiltersToolPanel',
                            }
                        ]
                    }}
                />
                {loading && (
                    <div className="loading-overlay">
                        <div className="spinner"></div>
                    </div>
                )}
            </div>
        </div>
    )
}