import React, { useState, useRef, useEffect } from 'react';

import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import './LostMoneyBreakdown.css'
import "ag-grid-enterprise";
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';

import { currencyFormatter, dateFormatter } from '../valueformatter';

export default function LostMoneyBreakdown(props) {
  const gridOptions= {
    sideBar: true,
    rowGroupPanelShow: 'always',
    // groupDefaultExpanded: 1,
    columnHoverHighlight: true,
    // groupIncludeFooter: true,
    groupIncludeTotalFooter: true,
    suppressAggFuncInHeader: true,
    autoGroupColumnDef: {
      cellRendererParams: {
        footerValueGetter: params => {
          const isRootLevel = params.node.level === -1;
          if (isRootLevel) {
            return 'Grand Total';
          }
          return `Sub Total (${params.value})`;
        },
      },
      headerName: 'Payment Method',
    },
    getRowStyle: params => {
      // Grand Total row formatting
      if (params.node.group && params.node.level === -1) {
          return { backgroundColor: '#3F91B8', color: 'white', fontWeight: 'bold' };
      }
      // Subtotal row formatting
      if (params.node.group) {
          return { backgroundColor: '#b5c4ca', fontWeight: 'bold' };
      }
      // Alternating row colors
      return { backgroundColor: params.node.rowIndex % 2 === 0 ? '#f9f9f9' : '#c3e6f7' };
  },
    autoSizeStrategy: {
      type: 'fitCellContents'
    },
  };

  const gridRef = useRef(); // Optional - for accessing Grid's API

  // DefaultColDef sets props common to all Columns
  const defaultColDef = {
    filter: true,
  };


  // Column Definitions: Defines the columns to be displayed.
  const colDefs = [
    { field: "method", headerName: "Payment Method", enableRowGroup: true, rowGroup: true, sort: "asc", hide: true },
    { 
      field: "payment_date", headerName: "Payment Date", enableRowGroup: true, sort: "asc",
      valueFormatter: params => dateFormatter(params.value),
    },
    { field: "case_number", headerName: "Case #", enableRowGroup: true },
    {
      field: "paid_amount", headerName: "Paid Amount", cellStyle: { textAlign: 'right' }, aggFunc: "sum",
      valueFormatter: params => currencyFormatter(params.value, "$"),
    },
  ];

  // Row Data: The data to be displayed.
  const [rowData, setRowData] = useState([]);

  // Preserving Filter Model
  const [filterModel, setFilterModel] = useState(null);  // Save current filters

  // Loading state to track when data is being fetched
  const [loading, setLoading] = useState(true);

  // Fetch data based on date range
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to true before fetching
      if (gridRef.current) {
        // Save current filter state
        const currentFilterModel = gridRef.current.api.getFilterModel();
        setFilterModel(currentFilterModel);
      }

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          replaceKeys: {
            "{{startDate}}": props.startDate,
            "{{endDate}}": props.endDate
          }
        })
      };
      const reqUrl = `${process.env.REACT_APP_QF_API}/${process.env.REACT_APP_OTG_ID}/data/EXkbfzr4qDboxmkUsZuY`;

      try {
        const result = await fetch(reqUrl, requestOptions);
        const respData = await result.json();
        // Check for error 
        if(respData.status !== "success") {
          alert("Error fetching data: " + respData.msg);
          setRowData([]);
          return;
        }
        setRowData(respData.data);
      } catch (error) {
        console.error("ERROR: " + error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchData();
  }, [props.reportName, props.startDate, props.endDate]);

  // After new data is set, reapply the filter model
  useEffect(() => {
    if (gridRef.current && filterModel) {
      gridRef.current.api.setFilterModel(filterModel);
    }
  }, [rowData, filterModel]);

  // This will autosize these columns since I think the reason they're not be resized with the gridoption above
  // is b/c we're calculating them and creating here instead of the data coming straght from the API
  const onGridReady = (params) => {
    params.api.sizeColumnsToFit(); // Resize columns to fit the grid's width
    const columnIds = ['method', 'payment_date', 'case_number', 'paid_amount'];
    params.api.autoSizeColumns(columnIds); // Auto-size the specific columns
  };

  return (
    // wrapping container with theme & size
    <div
      className="ag-theme-quartz h-78 greyBg" // applying the grid theme
    >
      <div id="summaryBox">
        <p id="summaryText">
          <strong>Lost Money Breakdown</strong><br />
          This view will show all negative payments by Payment Method, Payment Date, and Case Number.
        </p>
      </div>
      <div style={{ position: 'relative', height: '85%' }}> {/* New wrapper for AG Grid */}
        <AgGridReact
          enableCharts={true}
          enableRangeSelection={true}
          onGridReady={onGridReady}
          rowData={rowData}
          columnDefs={colDefs}
          // getGroupRowAgg={getGroupRowAgg}
          gridOptions={gridOptions}
          defaultColDef={defaultColDef}
          modules={[ServerSideRowModelModule, RowGroupingModule]}
          defaultSideBar={false}
          sideBar={{
            toolPanels: [
              {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
              },
              {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
              }
            ]
          }}
        />
        {loading && (
          <div className="loading-overlay">
            <div className="spinner"></div>
          </div>
        )}
      </div>
    </div>
  )
}