import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';

import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import './CallAnalysisBreakdownWithUnderlyingData.css'
import "ag-grid-enterprise";
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';

import { adservRatio, percentageOf, percentageFormatter, currencyFormatter, dateFormatter, dateFormatterAddPSTSuffix } from '../valueformatter';

export default function CallAnalysisBreakdownWithUnderlyingData(props) {
  const [gridOptions, setGridOptions] = useState({
    sideBar: true,
    rowGroupPanelShow: 'always',
    // groupDefaultExpanded: 1,
    columnHoverHighlight: true,
    // groupIncludeFooter: true,
    groupIncludeTotalFooter: true,
    suppressAggFuncInHeader: true,
    autoGroupColumnDef: {
      cellRendererParams: {
        footerValueGetter: params => {
          const isRootLevel = params.node.level === -1;
          console.log("isRootLevel: ", isRootLevel);
          if (isRootLevel) {
            return 'Grand Total';
          }
          return `Sub Total (${params.value})`;
        },
      },
      headerName: 'Queue',
    },
    getRowStyle: params => {
      // Grand Total row formatting
      if (params.node.group === true && params.node.level === -1) {
        return { background: '#3F91B8', color: 'white', fontWeight: 'bold' };
        // #b5c4ca
        // #99bac9
      }
      // Subtotal row formatting
      else if (params.node.group === true) {
        return { background: '#b5c4ca', fontWeight: 'bold' };
      }
    },
    autoSizeStrategy: {
      type: 'fitCellContents'
    },

    // Disable expanding rows
  });

  const gridRef = useRef(); // Optional - for accessing Grid's API

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => ({
    filter: true,
  }));

  const getGroupRowAgg = useCallback((params) => {
    const result = {
      total_calls: 0,
      accepted_calls: 0,
      missed_calls: 0,
      abandon_rate: 0,
      cases: 0,
      deals: 0,
      close_ratio: 0,
      calls_gt_2min: 0,
      calls_gt_2min_case_ratio: 0,
      calls_gt_2min_deal_ratio: 0,
      calls_gt_5min: 0,
      calls_gt_5min_case_ratio: 0,
      calls_gt_5min_deal_ratio: 0,
      calls_gt_10min: 0,
      calls_gt_10min_case_ratio: 0,
      calls_gt_10min_deal_ratio: 0,
      calls_gt_45min: 0,
      calls_gt_45min_case_ratio: 0,
      calls_gt_45min_deal_ratio: 0,
    }
    
    let unique_cases = {};
    let unique_deals = {};
    params.nodes.forEach((node) => {
      const data = node.group ? node.aggData : node.data
      if (node.group) {
        // For groups, sum up the pre-calculated totals (Grand Total)
        result.total_calls += data.total_calls;
        result.accepted_calls += data.accepted_calls;
        result.missed_calls += data.missed_calls;
        if (data.total_calls > 0) {
          result.abandon_rate = (result.missed_calls / result.total_calls) * 100;
        }

        result.cases += data.cases;
        result.deals += data.deals;

        if (result.cases > 0) {
          result.close_ratio = (result.deals / result.cases) * 100;
        }

        // 2 Min
        result.calls_gt_2min += data.calls_gt_2min;
        if (result.calls_gt_2min > 0) {
          result.calls_gt_2min_case_ratio = (result.cases / result.calls_gt_2min) * 100;
        }
        if (result.calls_gt_2min > 0) {
          result.calls_gt_2min_deal_ratio = (result.deals / result.calls_gt_2min) * 100;
        }

        // 5 Min
        result.calls_gt_5min += data.calls_gt_5min;
        if (result.calls_gt_5min > 0) {
            result.calls_gt_5min_case_ratio = (result.cases / result.calls_gt_5min) * 100;
        }
        if (result.calls_gt_5min > 0) {
            result.calls_gt_5min_deal_ratio = (result.deals / result.calls_gt_5min) * 100;
        }

        // 10 Min
        result.calls_gt_10min += data.calls_gt_10min;
        if (result.calls_gt_10min > 0) {
            result.calls_gt_10min_case_ratio = (result.cases / result.calls_gt_10min) * 100;
        }
        if (result.calls_gt_10min > 0) {
            result.calls_gt_10min_deal_ratio = (result.deals / result.calls_gt_10min) * 100;
        }

        // 45 Min
        result.calls_gt_45min += data.calls_gt_45min;
        if (result.calls_gt_45min > 0) {
            result.calls_gt_45min_case_ratio = (result.cases / result.calls_gt_45min) * 100;
        }
        if (result.calls_gt_45min > 0) {
            result.calls_gt_45min_deal_ratio = (result.deals / result.calls_gt_45min) * 100;
        }

      } else {
        // For individual records, count based on result
        if (data.result === "Accepted") {
          result.accepted_calls += 1;
        } else if (data.result === "Missed") {
          result.missed_calls += 1;
        }
        // Calculate total calls
        result.total_calls = result.accepted_calls + result.missed_calls;

        // Calculate abandon rate
        if (result.total_calls > 0) {
          result.abandon_rate = (result.missed_calls / result.total_calls) * 100;
        }
        
        // Count unique cases
        if (data.case_number ) {
          unique_cases[data.case_number] = true;
          result.cases += 1;
        }

        // Count deals
        if (data.case_number && data.sale_date) {
            unique_deals[data.case_number] = true;
            result.deals += 1;
        }

        // Calculate close ratio
        if (result.cases > 0) {
          result.close_ratio = (result.deals / result.cases) * 100;
        }

        // Calculate calls > 2min
        if (data.duration > 120) {
          result.calls_gt_2min += 1;
        }

        // Calculate calls > 2min case ratio
        if (result.calls_gt_2min > 0) {
          result.calls_gt_2min_case_ratio = (result.cases / result.calls_gt_2min) * 100;
        }

        // Calculate calls > 2min deal ratio
        if (result.calls_gt_2min > 0) {
          result.calls_gt_2min_deal_ratio = (result.deals / result.calls_gt_2min) * 100;
        }

        // Calculate calls > 5min
        if (data.duration > 300) {
          result.calls_gt_5min += 1;
        }

        // Calculate calls > 5min case ratio
        if (result.calls_gt_5min > 0) {
          result.calls_gt_5min_case_ratio = (result.cases / result.calls_gt_5min) * 100;
        }

        // Calculate calls > 5min deal ratio
        if (result.calls_gt_5min > 0) {
          result.calls_gt_5min_deal_ratio = (result.deals / result.calls_gt_5min) * 100;
        }

        // Calculate calls > 10min
        if (data.duration > 600) {
          result.calls_gt_10min += 1;
        }

        // Calculate calls > 10min case ratio
        if (result.calls_gt_10min > 0) {
          result.calls_gt_10min_case_ratio = (result.cases / result.calls_gt_10min) * 100;
        }

        // Calculate calls > 10min deal ratio
        if (result.calls_gt_10min > 0) {
          result.calls_gt_10min_deal_ratio = (result.deals / result.calls_gt_10min) * 100;
        }

        // Calculate calls > 45min
        if (data.duration > 2700) {
            result.calls_gt_45min += 1;
        }

        // Calculate calls > 45min case ratio
        if (result.calls_gt_45min > 0) {
            result.calls_gt_45min_case_ratio = (result.cases / result.calls_gt_45min) * 100;
        }

        // Calculate calls > 45min deal ratio
        if (result.calls_gt_45min > 0) {
            result.calls_gt_45min_deal_ratio = (result.deals / result.calls_gt_45min) * 100;
        }
      }
    });
    
    return result;
  });
  

  console.log("props: ", props);

  // Column Definitions: Defines the columns to be displayed.
  const [colDefs, setColDefs] = useState([
    { field: "to__name", enableRowGroup: true, rowGroup: true, sort: "asc", hide: true },
    // { field: "to__phone_number",  enableRowGroup: true, cellStyle: { textAlign: 'right' }},
    // { field: "from__name", enableRowGroup: true, },
    // { field: "from__phone_number",  enableRowGroup: true, cellStyle: { textAlign: 'right' }},
    // { field: "leg_id", enableRowGroup: true, cellStyle: { textAlign: 'right' }},
    // { field: "session_id", enableRowGroup: true, cellStyle: { textAlign: 'right' }},
    // { field: "start_time", enableRowGroup: true, cellStyle: { textAlign: 'right' }},
    // { field: "result",  enableRowGroup: true, },
    // { field: "duration",cellStyle: { textAlign: 'right' } },
    { field: "total_calls", headerName: "Total Calls", cellStyle: { textAlign: 'right' },
        valueGetter: params => {
            if (params.node.group) {
                return params.data.total_calls; // Return the aggregated count for group rows
            }
            return dateFormatterAddPSTSuffix(params.data.start_time); // Return the start time for leaf nodes
        },
    },
    // { field: "accepted_calls", headerName: "Accepted", cellStyle: { textAlign: 'right' },
    //     valueGetter: params => {
    //         if (params.node.group) {
    //             return params.data.accepted_calls; // Return the aggregated count for group rows
    //         }
    //         return params.data.session_id; // Return the start time for leaf nodes
    //     },
    // },
    { field: "missed_calls", headerName: "Dropped", cellStyle: { textAlign: 'right' }},
    { field: "abandon_rate", headerName: "Abandon Rate", cellStyle: { textAlign: 'right' },
        valueFormatter: params => percentageFormatter(params.value),
    },
    { field: "cases", headerName: "Cases", cellStyle: { textAlign: 'right' },
        valueGetter: params => {
            if (params.node.group) {
                return params.data.cases; // Return the aggregated count for group rows
            }
            return params.data.case_number; // Return the case number for leaf nodes
        }
    }
    ,
    { field: "deals", headerName: "Sold", cellStyle: { textAlign: 'right' },
        valueGetter: params => {
            if (params.node.group) {
                return params.data.deals; // Return the aggregated count for group rows
            }
            return dateFormatterAddPSTSuffix(params.data.create_date); // Return the sale date for leaf nodes
        }
    },
    { field: "close_ratio", headerName: "Close Ratio", cellStyle: { textAlign: 'right' },
        valueFormatter: params => percentageFormatter(params.value),
    },
    { field: "calls_gt_2min", headerName: "Calls >2 M", cellStyle: { textAlign: 'right' }},
    { field: "calls_gt_2min_case_ratio", headerName: "2 M Case Ratio", cellStyle: { textAlign: 'right' },
        valueFormatter: params => percentageFormatter(params.value),
    },
    { field: "calls_gt_2min_deal_ratio", headerName: "2 M Sold Ratio", cellStyle: { textAlign: 'right' },
        valueFormatter: params => percentageFormatter(params.value),
    },
    { field: "calls_gt_5min", headerName: "Calls >5 M", cellStyle: { textAlign: 'right' }},
    { field: "calls_gt_5min_case_ratio", headerName: "5 M Case Ratio", cellStyle: { textAlign: 'right' },
        valueFormatter: params => percentageFormatter(params.value),
    },
    { field: "calls_gt_5min_deal_ratio", headerName: "5 M Sold Ratio", cellStyle: { textAlign: 'right' },
        valueFormatter: params => percentageFormatter(params.value),
    },
    { field: "calls_gt_10min", headerName: "Calls >10 M", cellStyle: { textAlign: 'right' }},
    { field: "calls_gt_10min_case_ratio", headerName: "10 M Case Ratio", cellStyle: { textAlign: 'right' },
        valueFormatter: params => percentageFormatter(params.value),
    },
    { field: "calls_gt_10min_deal_ratio", headerName: "10 M Sold Ratio", cellStyle: { textAlign: 'right' },
        valueFormatter: params => percentageFormatter(params.value),
    },
    { field: "calls_gt_45min", headerName: "Calls >45 M", cellStyle: { textAlign: 'right' }},
    { field: "calls_gt_45min_case_ratio", headerName: "45 M Case Ratio", cellStyle: { textAlign: 'right' },
        valueFormatter: params => percentageFormatter(params.value),
    },
    { field: "calls_gt_45min_deal_ratio", headerName: "45 M Sold Ratio", cellStyle: { textAlign: 'right' },
        valueFormatter: params => percentageFormatter(params.value),
    },
  ]);

  // Row Data: The data to be displayed.
  const [rowData, setRowData] = useState([]);

  // Preserving Filter Model
  const [filterModel, setFilterModel] = useState(null);  // Save current filters

  // Loading state to track when data is being fetched
  const [loading, setLoading] = useState(true);

  // Fetch data based on date range
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to true before fetching
      if (gridRef.current) {
        // Save current filter state
        const currentFilterModel = gridRef.current.api.getFilterModel();
        setFilterModel(currentFilterModel);
      }

      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      };
      const reqUrl = `${process.env.REACT_APP_API_URL}/api/reports?report=${props.reportName}&startDate=${props.startDate}&endDate=${props.endDate}`;

      try {
        const result = await fetch(reqUrl, requestOptions);
        const gridData = await result.json();
        setRowData(gridData.data);
      } catch (error) {
        console.error("ERROR: " + error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchData();
  }, [props.reportName, props.startDate, props.endDate]);

  // After new data is set, reapply the filter model
  useEffect(() => {
    if (gridRef.current && filterModel) {
      gridRef.current.api.setFilterModel(filterModel);
    }
  }, [rowData]);

  // If rows have not yet been returned
  // if (loading) {
  //   return (
  //     <div>
  //       <h1 className='color2-font'>DATA LOADING</h1>
  //     </div>
  //   )
  // }

  // Subtotals/Grand Total row formatting
  const groupColDef = {
    cellRendererParams: {
      footerValueGetter: params => {
        const isRootLevel = params.node.level === -1;
        if (isRootLevel) {
          return 'Grand Total';
        }
        return `Sub Total (${params.value})`;
      },
    },
    headerName: 'Queue',
  }

  // possible options: 'never', 'always', 'onlyWhenGrouping'
  // const rowGroupPanelShow = 'always';

  // This will autosize these columns since I think the reason they're not be resized with the gridoption above
  // is b/c we're calculating them and creating here instead of the data coming straght from the API
  const onGridReady = (params) => {
    params.api.sizeColumnsToFit(); // Resize columns to fit the grid's width
    const columnIds = ['ip_roi', 'tp_roi', 'avg_ip', 'cpa'];
    params.api.autoSizeColumns(columnIds); // Auto-size the specific columns
  };

  return (
    // wrapping container with theme & size
    <div
      className="ag-theme-quartz h-78 greyBg" // applying the grid theme
    // style={{ height: 100% }} // the grid will fill the size of the parent container 
    >
      <div id="summaryBox">
        <p id="summaryText">
          <strong>Call Analysis Breakdown</strong><br />
          This view will show data for inbound calls and Logics cases for each marketing line grouped by Marketing Line within the filtered date range.
          {/* <br />
          <ul>
            <li>Calls are filtered by duration greater than 4 seconds</li>
            <li>If a vendor has NO SPEND with the Start/End Date, it will show N/A</li>
            <li>If the last day we have actual spend is before the end date, that value will be highlighted yellow</li>
          </ul> */}
        </p>
      </div>
      <div style={{ position: 'relative', height: '100%' }}> {/* New wrapper for AG Grid */}
        <AgGridReact
          enableCharts={true}
          enableRangeSelection={true}
          onGridReady={onGridReady}
          rowData={rowData}
          columnDefs={colDefs}
          getGroupRowAgg={getGroupRowAgg}
          gridOptions={gridOptions}
          defaultColDef={defaultColDef}
          modules={[ServerSideRowModelModule, RowGroupingModule]}
          defaultSideBar={false}
          sideBar={{
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                },
                {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',
                }
            ]
        }}    
        />
        {loading && (
            <div className="loading-overlay">
            <div className="spinner"></div>
            </div>
        )}
      </div>
    </div>
  )
}