
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import otgLogo from '../static/otgLogo.png';
import CallAnalysisBreakdown from '../callAnalysisBreakdown/CallAnalysisBreakdown';
import LostMoneyBreakdown from '../lostMoneyBreakdown/LostMoneyBreakdown';
import MarketingPerformance from '../marketingPerformance/MarketingPerformance';


import './ReportQuery.css';
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { initializeApp } from 'firebase/app';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';
import CallAnalysisBreakdownWithUnderlyingData from '../callAnalysisBreakdownWithUnderlyingData/CallAnalysisBreakdownWithUnderlyingData';


const yesterdaysDate = () => {
  const today = new Date();
  const losAngelesTime = new Date(today.toLocaleString('en-US', { timeZone: 'America/Los_Angeles' }));
  let yesterday = new Date(losAngelesTime);
  yesterday.setDate(losAngelesTime.getDate() - 1);
  yesterday.setHours(today.getHours() - losAngelesTime.getHours());
  const formattedYesterday = yesterday.toISOString().split('T')[0];
  return formattedYesterday;
};

const isDateValid = (inputDate) => {
  const today = new Date();
  today.setDate(today.getDate());
  const input = new Date(inputDate);
  return input <= today;
};

// Firebase project configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_ID,
  appId: process.env.REACT_APP_APP_ID,
};

const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
export function ReportQuery({ managerList, adminList, loggedInUser }) {
  const datePickerRef = useRef(null);
  const [showDatePicker, setShowDatePicker] = useState(false);

  // Need this also to display the date range in the date picker
  // It's a list b/c you can do several date ranges (not applicable to our case)
  const [calendarDates, setCalendarDates] = useState(() => {
    const today = new Date();
    const monday = new Date(today);
    monday.setDate(today.getDate() - today.getDay() + 1); // Set to Monday of current week
    const friday = new Date(today);
    friday.setDate(today.getDate() - today.getDay() + 5); // Set to Friday of current week
    
    return [{
        startDate: monday,
        endDate: friday,
        key: 'selection'
    }];
});
      
  const [reportName, setReportName] = useState('');
  const [reportComp, setReportComp] = useState(null);
  const [, setLoggedInUser] = useState({});
  let [loggedIn, setLoggedIn] = useState(true);

  // Log User Out
  const userLogOut = () => {
    signOut(auth)
      .then(() => {
        navigate("/")
      })
      .catch((error) => {
        alert("Unable to sign out: " + error.message);
      })
  }
  
  // Add this useEffect
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
        setShowDatePicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const updateReport = (e) => {
    // console.log("e.target.value: " + e.target.value)
    setReportName(e.target.value);
  };

//   const updateStartDate = (e) => {
//     const enteredDate = e.target.value;
//     // const updateval = isDateValid(enteredDate) ? enteredDate : yesterdaysDate();
//     setStartDate(enteredDate);
//   };

//   const updateEndDate = (e) => {
//     const enteredDate = e.target.value;
//     // const updateval = isDateValid(enteredDate) ? enteredDate : yesterdaysDate();
//     setEndDate(enteredDate);
//   };

  const displayReport = (e) => {
    // console.log("reportName: ", reportName);
    let rc = null;
    if (calendarDates[0].startDate.toLocaleDateString() !== '' && calendarDates[0].endDate.toLocaleDateString() !== '') {
        // console.log("Fetching report for: " + reportName + " from " + calendarDates[0].startDate.toLocaleDateString() + " to " + calendarDates[0].endDate.toLocaleDateString());
      if (reportName === 'callAnalysisBreakdown') {
        rc = <CallAnalysisBreakdown startDate={calendarDates[0].startDate.toLocaleDateString()} endDate={calendarDates[0].endDate.toLocaleDateString()} reportName={'callAnalysisBreakdown'} />;
      } else if (reportName === 'callAnalysisBreakdownWithUnderlyingData') {
        rc = <CallAnalysisBreakdownWithUnderlyingData startDate={calendarDates[0].startDate.toLocaleDateString()} endDate={calendarDates[0].endDate.toLocaleDateString()} reportName={'callAnalysisBreakdownWithUnderlyingData'} />;
      } else if (reportName === 'lostMoneyBreakdown') {
        rc = <LostMoneyBreakdown startDate={calendarDates[0].startDate.toLocaleDateString()} endDate={calendarDates[0].endDate.toLocaleDateString()} reportName={'lostMoneyBreakdown'} />;
      } else if (reportName === 'marketingPerformance') {
        rc = <MarketingPerformance startDate={calendarDates[0].startDate.toLocaleDateString()} endDate={calendarDates[0].endDate.toLocaleDateString()} reportName={'marketingPerformance'} />;
      }
    }
    setReportComp(rc);
  };

  const managerReports = {};

  const adminReports = {
    callAnalysisBreakdown: 'Call Analysis Breakdown',
    // CallAnalysisBreakdownWithUnderlyingData: 'Call Analysis Breakdown (with underlying data)', // This one is matching case ANI to call ANI and displays Case Number, call start time and sale date when you expand the rows.
    lostMoneyBreakdown: 'Lost Money Breakdown',
    marketingPerformance: 'Marketing Performance',
  };

  // If the user is a manager, they will get the manager report, if they are admin we combine both lists here
  const reportOptionsMap = managerList && loggedInUser && loggedInUser.email && managerList.includes(loggedInUser.email.toLowerCase())
    ? managerReports
    : adminList && loggedInUser && loggedInUser.email && adminList.includes(loggedInUser.email.toLowerCase())
    ? { ...managerReports, ...adminReports }
    : {};


  // Sort the reports
  const sortedOptions = Object.entries(reportOptionsMap).sort()
  const optionElements = sortedOptions.map(([key, value]) => (
    <option key={key} value={key}>
      {value}
    </option>
  ));

  // Add this useEffect at the component level
  // useEffect(() => {
  //   const defaultReport = sortedOptions.find(([key]) => key === 'callAnalysisBreakdown');
  //   if (defaultReport) {
  //   setReportName(defaultReport[0]);
  //   }
  // }, [sortedOptions]); // Only runs when sortedOptions changes

  // Check logged in state
  onAuthStateChanged(auth, (user) => {
    // console.log("user: ", user);
    // Set the user to the state
    setLoggedInUser(user);
    if (user && user.emailVerified) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user
      // console.log("user.email: ", user.email);
    } else {
      console.log("NOT LOGGED IN/VERIFIED");
      setLoggedIn(false);
    }
  });

  const navigate = useNavigate();
  if(!loggedIn)  {
    return navigate("/");
  }

  // Add this function to format the date range display
  const formatDateRange = () => {
    return `${calendarDates[0].startDate.toLocaleDateString()} - ${calendarDates[0].endDate.toLocaleDateString()}`;
  };

  // console.log("optionElements: ", optionElements);

  return (
    <div className=''>
      <div className='topBar'>
        <div className='f justify-center align-center'>
          <div className='f center-text'>
            <img src={otgLogo} alt="Logo" height={50} className='m-right-2' />
          </div>
          <div className='f gap-5'>
            {/* Logout button */}
            <button className='m-right-2 blueButton redBg' onClick={() => userLogOut()}>Logout</button>
            <label className='align-content-center'>Report Name: </label>
            <select
              name="reportName"
              className='large-font rounded-5 dropdown-input'
              onChange={(e) => updateReport(e)}
            >
              <option value={""}></option>
              {optionElements}
            </select>
          </div>
          {/* <div className='f m-left-2 gap-5'>
            <label>Start Date: </label>
            <input
              name='startDate'
              className='large-font'
              id='startDate'
              type='date'
              defaultValue={'2024-10-14'}
            //   defaultValue={yesterdaysDate()}
              onChange={(e) => updateStartDate(e)}
            />
          </div>
          <div className='f m-left-2 gap-5'>
            <label>End Date: </label>
            <input
              name='endDate'
              className='large-font'
              id='endDate'
              type='date'
              defaultValue={'2024-10-18'}
            //   defaultValue={yesterdaysDate()}
              onChange={(e) => updateEndDate(e)}
            />
          </div> */}
          <div className='date-picker-container m-left-2' ref={datePickerRef}>
          <label className='large-font'>Date Range: </label>
            <button 
              className='large-font rounded-5 dropdown-input' 
              onClick={() => setShowDatePicker(!showDatePicker)}>
                {formatDateRange()}
            </button>
            
            {showDatePicker && (
              <div>
                <DateRangePicker
                    className='date-picker-wrapper'
                    onChange={item => {
                      setCalendarDates([item.selection]);
                    }}
                    showSelectionPreview={true}
                    // moveRangeOnFirstSelection={false}
                    // retainEndDateOnFirstSelection={true}
                    months={1}
                    ranges={calendarDates}
                    direction="vertical"
                    editableDateInputs={true}
                    dateDisplayFormat="MM/dd/yyyy"
                />
                {/* <button className='blueButton' onClick={() => setShowDatePicker(false)}>Close</button> */}
                </div>
            )}
          </div>
          <div className='f center-text m-left-2'>
            <button className='blueButton' onClick={(e) => displayReport(e)}>Fetch Report</button>
          </div>
        </div>
      </div>
      <div id="reportBox">{reportComp}</div>
    </div>
  );
};
export default ReportQuery;
